import React from "react";
import {HeadFC} from "gatsby";
import {Header} from "../components/header";
import {Footer} from "../components/footer";
import {useParosSkeletonStyle} from "../hooks/use_paros_skeleton";
import {SEO} from "../components/seo";

const InsurancePackagesPage = () => {
  return (
    <>
      <Header/>
      <main className="h-full">
        <section className="h-full sm:h-1/5 max-w-6xl mx-auto pt-10 pb-14" style={useParosSkeletonStyle()}>
          <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
            <h1 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 font-poppins">
              Rental Terms
            </h1>
          </div>
        </section>
        <section className="w-full bg-white py-20 px-8">
          <div className="max-w-6xl mx-auto">
            <h2 className="text-3xl mb-2"><b>General Rental Terms:</b></h2>
            <p>At Coolcar, we believe in providing our customers with a hassle-free car rental experience. Our rental prices include the following:</p>
            <ul className="list-disc ml-8 mt-2">
              <li>Unlimited mileage</li>
              <li>Second driver without extra charge</li>
              <li>Delivery and drop-off from/to Port-Airport-Hotel at no additional cost</li>
              <li>Child seat or booster upon request</li>
              <li>24-hour road assistance</li>
              <li>Replacement vehicle in the event of a breakdown</li>
              <li>Basic third-party insurance</li>
              <li>Theft protection insurance</li>
              <li>Crystal security</li>
              <li>Taxes (tax 24%)</li>
            </ul>
            <p className="mt-4"><strong>Minimum Rental Period:</strong></p>
            <p>The minimum rental period is 24 hours, and we offer free delivery during this period. We also provide a 30-minute grace period for the return of the vehicle. After this time, you will be charged a full day rental fee.</p>
            <p>Rent with confidence knowing that you have the support and protection you need with Coolcar. Book your rental car today.</p>

          </div>
        </section>
      </main>
      <Footer/>
    </>
  )
}

export const Head: HeadFC = () => (
  <SEO
    title="Coolcar Rental Terms | Hassle-Free Car Rental Experience"
    description="Discover Coolcar's hassle-free car rental experience. Our prices include unlimited mileage, insurance, delivery, and more. Book now for 24-hour assistance."
  />
);

export default InsurancePackagesPage;